import React from 'react'
import BackgroundImage from 'gatsby-background-image-es5'
import styled from 'styled-components';
import {useStaticQuery, graphql} from 'gatsby'

const HomeHero = ({children, className}) => {

  const data = useStaticQuery(
    graphql`
      query {
        bgImage: file(relativePath: { eq: "hero.jpg" }) {
          childImageSharp {
            fluid(quality: 90, maxWidth: 1920) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    `
  )

  const imageData = data.bgImage.childImageSharp.fluid;
	
	return (
		<BackgroundImage
        id="hero"
        fluid={imageData}
        className={className}
      >
			{children}
		</BackgroundImage>
    )
}

const StyledHomeHero = styled(HomeHero)`
  min-height: 40vmax;
  display: grid;
  align-items: center;
`

export default StyledHomeHero