import React from 'react'
import ContactForm from '../components/contact-form'
//import img from '../images/handshake.jpg';
import styled from 'styled-components';

const Contact = () => {
	return (

    <Section className="bg-gray-100" id="contact">
      <div className="prose-container mx-auto py-20">
        <h2 className="mb-4">Contact Us</h2>
        <p className="mb-12 text-xl">We want to hear from you! If you are interested in learning more about MYRLIN, or how you or your venture can partner with MYRLIN, please fill out the email form below with as much detail as possible.</p>
        <ContactForm className="" />
      </div>
    </Section>
	)
}

const Section = styled.section`

`
export default Contact
