import React from "react";
import Layout from "../layouts/layout";
import {AnchorLink} from 'gatsby-plugin-anchor-links';
import SEO from "../components/seo";
import StyledHomeHero from '../components/home-hero';
import About from '../components/about';
import Contact from '../components/contact';

export default ({data}) => (
  <Layout>
    <SEO title="Home" />

		<StyledHomeHero>
      <div className="prose-container mx-auto py-8">
        <div>
          <h1 className="mb-4 text-3xl md:text-4xl lg:text-5xl uppercase">Focus.<br />Value.<br />Action.</h1>
          <p className="mb-8">The Magic of Business.</p>
          <AnchorLink to="/#contact" className="self-start font-bold bg-yellow-400 rounded py-2 px-4">Learn More</AnchorLink>
        </div>
      </div>
		</StyledHomeHero>

    <About />
    
		<Contact />

  </Layout>
)