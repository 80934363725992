import React from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faCommentsDollar } from "@fortawesome/free-solid-svg-icons"
import { faChartLine } from "@fortawesome/free-solid-svg-icons"
import { faMicrochip } from "@fortawesome/free-solid-svg-icons"
import { faFingerprint } from "@fortawesome/free-solid-svg-icons"
const About = () => {
  return (
    <section className="bg-white py-20" id="about">

      <div className="prose-container mb-8">
        <h2 className="mb-2">About</h2>
        <p className="text-xl">MYRLIN is a Venture Studio providing entrepreneurs with the tools, team, funding, and developmental strategies to expedite bringing concepts to life, and scaling those ideas into successful, investible, and thriving businesses.</p>
      </div>
      <div className="about-cards prose-container">
        <div className="p-8 border">
          <h3 className="mb-2 flex gap-4">
            <FontAwesomeIcon className="text-blue-400 mb-2" size="lg" icon={faFingerprint} />
            Identity
          </h3>
          <p>Many businesses focus on <em>what</em> they do, and <em>how</em> they do it, but our Branding & Marketing Division develops <em>who</em> they are, and illuminates <em>why</em> they do what they do, as the ethos of the company.</p>
        </div>
        
        <div className="p-8 border">
          <h3 className="mb-2 flex gap-4">
            <FontAwesomeIcon className="text-blue-400 mb-2" size="lg" icon={faChartLine} />
            Strategy
          </h3>
          <p>Building comprehensive strategies for operations, capital formation, and fundraising, increase the likelihood of success. Our Finance Division creates custom formulas for its partners that drive results.</p>
        </div>

        <div className="p-8 border">
          <h3 className="mb-2 flex gap-4">
            <FontAwesomeIcon className="text-blue-400 mb-2" size="lg" icon={faMicrochip} />
            Technology
          </h3>
          <p>All industries are being radically transformed by technology, and&#8212;more importantly&#8212;customer interaction. Our Tech Division works to optimize solutions for targeting, acquiring, and increasing the lifetime value of their customer.</p>
        </div>

        <div className="p-8 border">
          <h3 className="mb-2 flex gap-4">
            <FontAwesomeIcon className="text-blue-400 mb-2" size="lg" icon={faCommentsDollar} />
            Funding
          </h3>
          <p>This is the biggest challenge of any new venture. By investing in our partners’ businesses, we provide the tools for diversified and transformative funding solutions to achieve the vision of the organization.</p>
        </div>
      </div>
    </section>
  )
}

export default About
