import React from 'react'


const ContactForm = ({className}) => {
  return (
    <form className={className} method="post" name="contact" data-netlify="true" data-netlify-honeypot="bot-field">
        <p class="hidden"><label>Don’t fill this out if you’re human: <input name="bot-field" /></label>
        </p>

        <label className="block">
          <span className="text-sm font-bold">Name</span>
          <input id="name" className="mt-1 block w-full rounded bg-gray-200 border-transparent focus:border-gray-500 focus:placeholder-transparent focus:bg-white focus:ring-0" type="text" name="name" />
        </label>

        <label className="block">
          <span className="text-sm font-bold">Email</span>
          <input id="email" className="mt-1 block w-full rounded bg-gray-200 border-transparent focus:border-gray-500 focus:placeholder-transparent focus:bg-white focus:ring-0" placeholder="username@example.com" type="email" name="email" />
        </label>

        <label className="block">
          <span className="text-sm font-bold">Message</span>
          <textarea className="mt-1 block w-full rounded bg-gray-200 border-transparent focus:border-gray-500 focus:placeholder-transparent focus:bg-white focus:ring-0" id="message" name="message" rows="4"></textarea>
        </label>

        <input type="hidden" name="form-name" value="contact" />
      
        <button className="bg-yellow-400 text-gray-700 py-2 px-3 mt-4 rounded font-bold w-full" type="submit">Send Email</button>
    </form>
  )
}

export default ContactForm
